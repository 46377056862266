.mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 30vw;
    margin: auto; /* This will center the container horizontally */
}
.title-style {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    color: darksalmon;
}
.mobile-button {
    width: 20vw;
    height: 5vh;
    margin-top: 100px;
}